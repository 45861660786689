import React, { useState, useEffect } from 'react';

//SVGs
import { ADCTIcon, HelperIcon } from '../../../assets/icons';

// COMPONENTS
import AtopicDermatitisControlSidebar from './ADCTSidebar';
import AtopicDermatitisControlTutorial from './ADCTTutorial';

//Mixpanel
import MixpanelService, { MixpanelEvents } from '../../../libs/mixpanel';

//Utils
import { ADCT_TRACKER_SHOWN } from '../../../utils/constants'
import moment from 'moment-timezone';

const AtopicDermatitisControlWidget = props => {
  const { newClassName, toggleADCTVisibility } = props;
  const [showSideBar, setShowSideBar] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const checkFirstLogin = () => {
    const firstLoginValue = localStorage.getItem(ADCT_TRACKER_SHOWN);
    const today = moment().format('YYYY-MM-DD');

    if (!firstLoginValue || firstLoginValue !== today) {
      setShowSideBar(true);
    }

    localStorage.setItem(ADCT_TRACKER_SHOWN, today);
  };

  useEffect(() => {
    checkFirstLogin();
  }, []);

  return (
    <>
      <div onClick={() => {
          setShowSideBar(true)
          MixpanelService.track(MixpanelEvents.ADCTTrackerTapped);
        }} 
        className={'tracker-button ' + newClassName}
      >
        <div className="tracker-button-icon">
          <ADCTIcon />
        </div>
        <div className="tracker-button-content">
          <label className="tracker-button-label">Your day:</label>
          <span className="tracker-button-title">
            <h3>
              ADCT quiz
            </h3>
            <div
              onClick={e => {
                e.stopPropagation();
                setShowModal(true)
                MixpanelService.track(MixpanelEvents.ADCTTrackerToolTipTapped);
              }}
              className={`tooltip-container  exception-element-icon`}
            >
              <div className="tooltip-trigger" >
                <span className="icon-instructions">
                  <HelperIcon />
                </span>
              </div>
            </div>
          </span>
        </div>
      </div>
      <AtopicDermatitisControlSidebar
        showADCTSidebar={showSideBar}
        onTakeQuiz={() => {
          toggleADCTVisibility();
          MixpanelService.track(MixpanelEvents.ADCTTrackerTapped);
        }} 
        onClose={() => {setShowSideBar(false)}}
      />
      <AtopicDermatitisControlTutorial isModalOpen={showModal} onClose={() => {setShowModal(false)}}/>
    </>
  );
};

export default AtopicDermatitisControlWidget;
