import { flowRight as compose } from 'lodash';
import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

import AtopicDermatitisControlResults from '../components/trackers/ADCT/ADCTResults';

const ADCTContainer = props => {
  return (
    <div className="main-dashboard page-provider-prep">
      <div className="main-dashboard-content">
        <Switch>
          <Route
            path="/trends/AtopicDermatitisControlResults"
            exact={true}
            render={() => (
                <AtopicDermatitisControlResults userId={props.me.id} history={props.history} />
            )}
          />
        </Switch>
      </div>
    </div>
  );
};

export default compose(
  withRouter,
)(ADCTContainer);
