import React from 'react';

const SkinToday = () => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="30" cy="30" r="30" fill="#FF9672" />
      <path
        d="M30 12.3525C27.6826 12.3525 25.3878 12.809 23.2468 13.6958C21.1057 14.5827 19.1603 15.8826 17.5217 17.5212C14.2122 20.8307 12.353 25.3193 12.353 29.9996C12.3375 34.0745 13.7485 38.0264 16.3412 41.1702L12.8118 44.6996C12.5669 44.9478 12.401 45.2629 12.3351 45.6052C12.2691 45.9476 12.3061 46.3018 12.4412 46.6231C12.5878 46.9407 12.8254 47.2075 13.1239 47.3897C13.4224 47.5719 13.7683 47.6614 14.1177 47.6467H30C34.6803 47.6467 39.1689 45.7874 42.4784 42.478C45.7878 39.1685 47.6471 34.6799 47.6471 29.9996C47.6471 25.3193 45.7878 20.8307 42.4784 17.5212C39.1689 14.2118 34.6803 12.3525 30 12.3525ZM30 44.1172H18.3706L20.0118 42.4761C20.3405 42.1454 20.525 41.6982 20.525 41.232C20.525 40.7657 20.3405 40.3185 20.0118 39.9878C17.7011 37.6797 16.2621 34.6417 15.9401 31.3916C15.618 28.1414 16.4329 24.8802 18.2457 22.1635C20.0586 19.4467 22.7573 17.4426 25.8821 16.4925C29.0069 15.5424 32.3645 15.7051 35.3828 16.953C38.401 18.2008 40.8933 20.4566 42.4349 23.3359C43.9765 26.2152 44.4721 29.54 43.8373 32.7437C43.2025 35.9475 41.4764 38.832 38.9533 40.9059C36.4302 42.9798 33.2661 44.1147 30 44.1172Z"
        fill="white"
      />
    </svg>
  );
};

export default SkinToday;
