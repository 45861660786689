import React from 'react';
import Sidebar from '../../shared/Sidebar';

import IC_ADCTMain from '../../../assets/images/newBrandImages/IC_ADCTMain.svg';

const AtopicDermatitisControlSidebar = ({ showADCTSidebar, onClose, onTakeQuiz }) => {
  return (
    <Sidebar
      isOpen={showADCTSidebar}
      onClose={onClose}
      header=""
      fromFlare={true}>
        <div className="flare-container">
          <div className="flare-image">
            <img src={IC_ADCTMain} alt="Flare Day Tracker" />
            <span className="text text__24p700">ADCT Quiz</span>
          </div>
          <div className="flare-text">
            <p className="text text__14p400 text__centered text__black100 text__mb10">
                The Atopic Dermatitis Control Tool (ADCT) offers a comprehensive assessment of your eczema control in six quick questions.   
            </p>
            <p className="text text__14p400 text__centered text__black100 text__mt10">
                You can share your score with your doctor to help them better understand your condition.
            </p>
          </div>
          <div className="flare-actions">
            <button
              className="button button__secondary100 button__btn-16-700 button-rectangle-80"
              onClick={onTakeQuiz}>
                Take the quiz
            </button>
            <span
              className="text text__14p600 text__secondary100 text__capitalized text__mt10 pointer-flare"
              onClick={onClose}>
                Skip
            </span>
          </div>
        </div>
    </Sidebar>
  );
};

export default AtopicDermatitisControlSidebar;
