import React, { useEffect, useState } from 'react';

// COMPONENTS
import Sidebar from '../shared/Sidebar';
import FlareIcon from '../../assets/images/newBrandImages/FlareImage.svg';
import moment from 'moment-timezone';

// ICONS
import { FlareDay, GodDay } from '../../assets/icons';

// APOLLO
import { useMutation } from 'react-apollo';
import { flareTracker } from '../../libs/graphql/mutations/trackers';

// Mixpanel
import MixpanelService, {
  MIXPANEL_DAY_FORMAT,
  MIXPANEL_TIME_FORMAT,
  MixpanelEvents,
} from '../../libs/mixpanel';

const FlareTracker = ({
  showFlareTracker,
  showFlareTrackerNews,
  onClose,
  userId,
  date,
  flareData,
  onChange,
  source,
}) => {
  // STATES
  const [showFlareButtons, setShowFlareButtons] = useState(true);
  const [isFlareDay, setIsFlareDay] = useState(flareData?.flare_day || null);
  const [daysStreak, setDaysStreak] = useState(flareData?.days_streak || 1);

  // LOCAL
  const trackerDate = moment(date);
  const isToday = trackerDate.isSame(moment(), 'day');

  // MUTATIONS
  const [createFlareInfo] = useMutation(flareTracker);

  const startTracking = () => {
    setShowFlareButtons(true);
  };

  // EFECTS
  useEffect(() => {
    if (showFlareTrackerNews) {
      setShowFlareButtons(false);
    }
  }, [showFlareTrackerNews]);

  useEffect(() => {
    setIsFlareDay(flareData?.flare_day ?? null);
    setDaysStreak(flareData?.days_streak ?? 1);
  }, [flareData]);

  // EVENTS
  const skipFlareTracker = () => {
    onClose();
    setShowFlareButtons(true);
  };

  const trackTrackerUpdated = (flare, trackerDate) => {
    const FlareStatus = flare ? 'Flaring' : 'Not flaring';
    const FlareTrackerDate = moment(trackerDate)
      .add(12, 'hours')
      .utc()
      .format(`${MIXPANEL_DAY_FORMAT}T${MIXPANEL_TIME_FORMAT}`);

    MixpanelService.track(MixpanelEvents.FlareTrackerUpdated, {
      FlareStatus,
      FlareTrackerDate,
    });
    MixpanelService.setProfileProperty('FlareStatus', FlareStatus);
    MixpanelService.setProfileProperty('FlareTrackerDate', FlareTrackerDate);
  };

  const handleFlareTracker = async flare => {
    const currentValue = isFlareDay;
    setIsFlareDay(flare);

    try {
      await createFlareInfo({
        variables: {
          userid: userId,
          flare: flare,
          clientDate: date,
        },
      });
      trackTrackerUpdated(flare, date);
      if (onChange) {
        onChange(flare);
      }
    } catch (error) {
      setIsFlareDay(currentValue);
    }
  };

  // RENDER
  const renderNewsPrompt = () => {
    return (
      <div className="flare-container">
        <div className="flare-image">
          <img src={FlareIcon} alt="Flare Day Tracker" />
          <span className="text text__24p700">New Feature: Flare Tracker</span>
        </div>
        <div className="flare-text">
          <p className="text text__16p400 text__centered text__black100 text__mb10">
            Flare day or good day? Our new Flare Tracker helps you quickly and
            easily monitor your eczema.
          </p>
        </div>
        <div className="flare-actions">
          <button
            className="button button__secondary100 button__btn-16-700 button-rectangle-80"
            onClick={startTracking}>
            Start tracking!
          </button>
          <span
            className="text text__14p600 text__secondary100 text__capitalized text__mt10 pointer-flare"
            onClick={onClose}>
            Skip
          </span>
        </div>
      </div>
    );
  };

  return (
    <Sidebar
      isOpen={showFlareTracker}
      onClose={onClose}
      header=""
      fromFlare={true}>
      {!showFlareButtons ? (
        renderNewsPrompt()
      ) : (
        <div className="flare-container">
          <div className="flare-text">
            <p className="text text__24p700 text__centered text__black100 text__mb10">
              Hi there!
            </p>
            <p className="text text__24p700 text__centered text__black100">
              {isToday ? 'How are your flares today?' : 'How were your flares?'}
            </p>
          </div>
          <div className="flare-buttons">
            <div className="flare-button">
              <button
                className="flare-option"
                type="button"
                onClick={() => handleFlareTracker(true)}
                disabled={isFlareDay === true}>
                <FlareDay isSelect={isFlareDay === true} />
              </button>
              <p
                className={`text text__14p600 text__centered text__capitalized-first ${
                  isFlareDay ? 'text__danger100' : 'text__black100'
                }`}>
                Flaring
              </p>
            </div>
            <div className="flare-button">
              <button
                className="flare-option"
                type="button"
                onClick={() => handleFlareTracker(false)}
                disabled={isFlareDay === false}>
                <GodDay isSelect={isFlareDay === false} />
              </button>
              <p
                className={`text text__14p600 text__centered text__capitalized-first ${
                  isFlareDay === false ? 'text__secondary100' : 'text__black100'
                }`}>
                Flare free!
              </p>
            </div>
          </div>

          {isFlareDay === true ? (
            <div className="flare-text">
              <p className="text text__16p600 text__centered text__black100 text__mb10">
                Ouch!
              </p>
              <p className="text text__15p400 text__centered text__black100 text__mb10">
                Looks like you have been flaring for {daysStreak}{' '}
                {daysStreak > 1 ? 'days' : 'day'}. Tracking your symptoms can
                help you get answers faster.
              </p>
              <p className="text text__15p400 text__centered text__black100 text__mt10">
                Don't forget to complete your PO-SCORAD so you can get a bigger
                picture of your eczema.
              </p>
            </div>
          ) : (
            ''
          )}

          {isFlareDay === false ? (
            <div className="flare-text">
              <p className="text text__16p600 text__centered text__black100 text__mb10">
                Great news!
              </p>
              <p className="text text__15p400 text__centered text__black100 text__mb10">
                You’ve been flare free for {daysStreak}{' '}
                {daysStreak > 1 ? 'days' : 'day'}! Keep up the good work
                tracking.
              </p>
              <p className="text text__15p400 text__centered text__black100 text__mt10">
                Don't forget to complete your PO-SCORAD so you can get a bigger
                picture of your eczema.
              </p>
            </div>
          ) : (
            ''
          )}

          <div className="flare-actions">
            {isFlareDay === true || isFlareDay === false ? (
              <button
                className="button button__secondary100 button__btn-16-700 button-rectangle-80"
                type="button"
                onClick={skipFlareTracker}>
                {source === 'Learn' ? 'Go back' : 'Go to track page'}
              </button>
            ) : (
              <span
                className="text text__14p600 text__secondary100 text__capitalized text__mt10 pointer-flare"
                onClick={skipFlareTracker}>
                {flareData === null ? 'Skip' : 'Close'}
              </span>
            )}
          </div>
        </div>
      )}
    </Sidebar>
  );
};

export default FlareTracker;
