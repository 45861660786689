import React from 'react';
import { Route, withRouter } from 'react-router-dom';
import TrendsContainer from '../components/trends/TrendsContainer';

const Dashboard = ({ me, ...props }) => {
  return <Route path="/trends" render={() => <TrendsContainer 
    me={me} 
    selectedDate={
    props.location.state
      ? props.location.state.selectedDate
      : null
    }
    scrollToADCT={
      props.location.state
        ? props.location.state.scrollToADCT
        : null
      }
   />} />
};

export default withRouter(Dashboard);
