import React, { PureComponent } from 'react';
import Select from 'react-select';
import { withApollo } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import moment from 'moment-timezone';
import { withTrendsView } from '../../apollo/stores/trendsView';
import analytics from '../../libs/analytics';

/**
 * TrendsViewPicker:
 * A reusable component that adusts the date range we want to look at in the
 * application. Defaults to "week"
 * -------------------------------------------------------------------------- */
class TrendsViewPicker extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      viewOptions: [
        { value: 'week', label: 'Week' },
        { value: 'month', label: 'Month' },
      ],
    };
  }

  componentDidMount() {
    if (this.props.selectedDate) {
      this.props.client.writeData({
        data: {
          trendsView: {
            startDate: this.props.selectedDate,
            endDate: moment(this.props.selectedDate)
              .add(6, 'days')
              .format('YYYY-MM-DD'),
            selectedDate: this.props.selectedDate,
            __typename: 'trendsView',
          },
        },
      });
    } else {
      this.setViewRange(this.state.viewOptions[0]);
    }
  }

  setViewRange = viewOption => {
    // Set the date in the store
    let endDate = moment().format('YYYY-MM-DD');
    let startDate = null;
    if (this.props.trendsView.endDate) {
      endDate = this.props.trendsView.endDate;
    }
    if (viewOption.value === 'month') {
      startDate = moment(endDate)
        .subtract(1, 'months')
        .add(1, 'days')
        .format('YYYY-MM-DD');
    } else {
      startDate = moment(endDate).subtract(6, 'days').format('YYYY-MM-DD');
    }
    let updateSelectedDate = !(
      moment(this.props.trendsView.selectedDate).isAfter(startDate) &&
      moment(this.props.trendsView.selectedDate).isBefore(endDate)
    );
    if (this.props.onChangePeriod) {
      this.props.onChangePeriod(viewOption.value);
    }
    // store locally where other components can read it
    return this.props.client.writeData({
      data: {
        trendsView: {
          value: viewOption.value,
          label: viewOption.label,
          startDate: startDate,
          endDate: endDate,
          selectedDate: updateSelectedDate
            ? endDate
            : this.props.trendsView.selectedDate,
          __typename: 'trendsView',
        },
      },
    });
  };

  setSelectedDayToToday = () => {
    const today = moment().format('YYYY-MM-DD');
    this.props.client.writeData({
      data: {
        trendsView: {
          selectedDate: today,
          startDate:
            this.props.trendsView.value === 'week'
              ? moment(today).subtract(6, 'd').format('YYYY-MM-DD')
              : moment(today).subtract(1, 'M').add(1, 'd').format('YYYY-MM-DD'),
          endDate: today,
          __typename: 'trendsView',
        },
      },
    });
  };

  formattedDates() {
    let startDate, endDate;
    const sameYear =
      moment(this.props.trendsView.startDate).year() ===
      moment(this.props.trendsView.endDate).year();
    const dateFormat = {
      start: this.props.trendsView.value === 'week' ? 'MMM. D' : 'MMM.',
      newYear:
        this.props.trendsView.value === 'week' ? 'MMM. D, YYYY' : 'MMM., YYYY',
      end:
        this.props.trendsView.value === 'week' ? 'MMM. D, YYYY' : 'MMM., YYYY',
    };
    if (sameYear) {
      startDate = moment(this.props.trendsView.startDate).format(
        dateFormat.start,
      );
    } else {
      startDate = moment(this.props.trendsView.startDate).format(
        dateFormat.newYear,
      );
    }
    endDate = moment(this.props.trendsView.endDate).format(dateFormat.end);
    return `${startDate} – ${endDate}`;
  }

  render() {
    let todayButtonModifier;
    if (this.props.trendsView) {
      todayButtonModifier =
        this.props.trendsView.selectedDate === moment().format('YYYY-MM-DD')
          ? 'button-small__selected'
          : 'button-small__unselected';
      return (
        <div className="trends-view-picker">
          <div className="trends-view-picker-chart-view">
            <label
              className="subtext subtext__regular subtext__cobalt"
              htmlFor="trends-view-picker-menu">
              {this.props.label}
            </label>
            <Select
              className="form-item-select form-item-select__trends"
              classNamePrefix="form-select"
              inputId="trends-view-picker-menu"
              isSearchable={false}
              onChange={this.setViewRange}
              options={this.state.viewOptions}
              value={this.props.trendsView}
            />
          </div>
          <button
            className={`button button-small ${todayButtonModifier}`}
            onClick={this.setSelectedDayToToday}>
            Today
          </button>
          <span className="trends-view-picker-value">
            {this.formattedDates()}
          </span>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default compose(withTrendsView, withApollo)(TrendsViewPicker);
