import React from 'react';
import {
  BackCircleArrow,
  ForwardCircleArrow,
  SadTrackerIcon,
  SmileTrackerIcon,
} from '../../assets/icons';

const DragScale = ({
  maxValue = 10,
  level = 0,
  editingMode = true,
  onIncrease,
  onDecrease,
  onSetDirectly,
  scaleWidth = 0.875,
  simpleDesign = false,
  withoutRadius = false,
}) => (
  <div className="dragscale-wrapper dragscale-wrapper__simple">
    <div className="dragscale-body">
      {!simpleDesign && (
        <div className="dragscale-tab">
          {editingMode && (
            <button
              disabled={level === 0}
              className="scale-button__lg button button-action"
              onClick={onDecrease}>
              <BackCircleArrow />
            </button>
          )}
          <span className="tab-label title__big-number">{level}</span>

          {editingMode && (
            <button
              disabled={level === maxValue}
              className="scale-button__lg button button-action"
              onClick={onIncrease}>
              <ForwardCircleArrow />
            </button>
          )}
        </div>
      )}
      <div className={`scale${simpleDesign ? ' scale__simple' : ''}`}>
        {!simpleDesign && <SmileTrackerIcon />}
        {[...Array(maxValue + 1)].map((noop, i) => (
          <div
            key={i}
            style={{
              width: `${scaleWidth}rem`,
              backgroundColor:
                i <= level ? getScaleColor(level, maxValue) : '#D8DADD',
            }}
            className={
              withoutRadius ? 'scale-unit-skin button' : 'scale-unit button'
            }
            onClick={() => (editingMode ? onSetDirectly(i - 1) : null)}
          />
        ))}
        {!simpleDesign && <SadTrackerIcon />}
      </div>
    </div>
  </div>
);

function getScaleColor(level, maxValue) {
  var percentage = (level / maxValue) * 100;
  if (percentage <= 40) {
    return '#FF9672';
  } else if (percentage <= 70) {
    return '#E95D42';
  }
  return '#B33D26';
}

export default DragScale;
