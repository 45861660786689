import React, { useEffect, useState, useRef } from 'react';

// COMPONENTS
import * as Unicons from '@iconscout/react-unicons';
import AtopicDermatitisControlSummary from '../../shared/ADCTScale';
import Button from '../../shared/button';

// APOLLO
import { useQuery } from 'react-apollo';
import { LatestsAtopicDermatitisControlData } from '../../../libs/graphql/queries/data';

// OPTIONS
const severityOptions = [
  'None',
  'Mild',
  'Moderate',
  'Severe',
  'Very Severe',
];
const periodDaysOptions = [
  'Not at all',
  '1-2 days',
  '3-4 days',
  '5-6 days',
  'Every day',
];
const affectOptions = [
  'Not at all',
  'A little',
  'Moderately',
  'Very',
  'Extremely',
];
const periodNightsOptions = [
  'No nights',
  '1-2 nights',
  '3-4 nights',
  '5-6 nights',
  'Every night',
];
const affectOptionsVariation = [
  'Not at all',
  'A little',
  'Moderately',
  'A lot',
  'Extremely',
];

const AtopicDermatitisControlResults = props => {

  const { userId } = props;

  const [LatestData, setLatestData] = useState({});
  const mainView = useRef(null);

  // Queries for daily diet data
  const { data } = useQuery(LatestsAtopicDermatitisControlData, {
    variables: {
      userid: userId,
    },
  });

  useEffect(() => {
    if(data)
    {
      const ADCTData = data.latestAtopicDermatitisControlData;
      setLatestData(ADCTData[0])

      mainView.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [data]);

  
  const onCancel = () => {
    props.history.goBack();
  };

  return (
    <div ref={mainView} className="main-dashboard page-provider-prep">
      <div className="provider-prep-container">
        <header>
          <div className="date-bar">
            <div className="main-dashboard__sidebar">
              <div className="sidebar-title">
                <Unicons.UilTimes size="24" color="#000" onClick={onCancel} />
                <h1 className="headers headers__black100 headers__uppercase headers__mediumbold">
                  ADCT Results
                </h1>
              </div>
            </div>
          </div>
        </header>
        <div className="manage-container">
          <div className="manage-content">
            <div class="appointmentPrepStep">
            <div class="card overview">
                <div className="card-content">
                  <AtopicDermatitisControlSummary hideADCTResults hideADCTResume userId={userId}/>
                  <label>What do your results mean?</label>
                  <p>
                      A higher score means lower control of your eczema.  Your eczema may not be well-controlled if your score 
                      is 7 or greater, or if your score increased by 5 or more points since you last used ADCT.
                  </p>
                </div>
              </div>
              <div class="card overview">
                <div className="card-header bordered">
                  <h3>Your ADCT Quiz score, in detail:</h3>
                  <hr></hr>
                </div>
                <div className="card-content">
                  <div className="indications">
                    <strong>0 =</strong>
                    <span>Never ,</span>
                    <strong>4 =</strong>
                    <span>extremely</span>
                  </div>
                  <div className="overview-results">
                    <div className="overview-result">
                      <div className="result-number">
                        <span>{ LatestData.rate_symptoms }</span>
                      </div>
                      <p>
                        Over the last week, how would you rate your eczema-related symptoms (for example, itching, dry skin, skin rash)? 
                        <br/>
                        <strong>{severityOptions[LatestData.rate_symptoms]}</strong>
                      </p>
                    </div>
                    <div className="overview-result">
                      <div className="result-number">
                        <span>{ LatestData.itching_episodes }</span>
                      </div>
                      <p>
                        Over the last week, how many days did you have intense episodes of itching because of your eczema?
                        <br/>
                        <strong>{periodDaysOptions[LatestData.itching_episodes]}</strong>
                      </p>
                    </div>
                    <div className="overview-result">
                      <div className="result-number">
                        <span>{ LatestData.rate_bothered}</span>
                      </div>
                      <p>
                        Over the last week, how bothered have you been by your eczema? 
                        <br/>
                        <strong>{affectOptions[LatestData.rate_bothered]}</strong>
                      </p>
                    </div>
                    <div className="overview-result">
                      <div className="result-number">
                        <span>{ LatestData.affect_sleep  }</span>
                      </div>
                      <p>
                        Over the last week, how many nights did you have trouble falling or staying asleep because of your eczema? 
                        <br/>
                        <strong>{periodNightsOptions[LatestData.affect_sleep]}</strong>
                      </p>
                    </div>
                    <div className="overview-result">
                      <div className="result-number">
                        <span>{ LatestData.affect_activities  }</span>
                      </div>
                      <p>
                        Over the last week, how much did your eczema affect your daily activity? 
                        <br/>
                        <strong>{affectOptionsVariation[LatestData.affect_activities]}</strong>
                      </p>
                    </div>
                    <div className="overview-result">
                      <div className="result-number">
                        <span>{ LatestData.affect_emotions  }</span>
                      </div>
                      <p>
                        Over the last week, how much did your eczema affect your mood or emotions? 
                        <br/>
                        <strong>{affectOptionsVariation[LatestData.affect_emotions]}</strong>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-buttons-bar">
            <div className="buttons-container">
              <Button
                onClick={onCancel}
                className="button button__secondary100 button__btn-14-600 button-rectangle-skin-footer"
                text={'Close'}
              />
            </div>
          </div>
      </div>
    </div>   
    );
};

export default AtopicDermatitisControlResults;
