import React, { useState, memo } from 'react';
import { useHistory } from "react-router-dom";
import moment from 'moment-timezone';

// APOLLO
import { useMutation } from 'react-apollo';
import { atopicDermatitisControlTracker } from '../../../libs/graphql/mutations/trackers';

// COMPONENTS
import { default as Multiselect } from '../../shared/Multiselect';
import * as Unicons from '@iconscout/react-unicons';
import Button from '../../shared/button';

//Mixpanel
import MixpanelService, 
{ MixpanelEvents, 
  MIXPANEL_DAY_FORMAT, 
  MIXPANEL_TIME_FORMAT 
} from '../../../libs/mixpanel';

// OPTIONS
const severityOptions = [
  'None',
  'Mild',
  'Moderate',
  'Severe',
  'Very Severe',
];
const periodDaysOptions = [
  'Not at all',
  '1-2 days',
  '3-4 days',
  '5-6 days',
  'Every day',
];
const affectOptions = [
  'Not at all',
  'A little',
  'Moderately',
  'Very',
  'Extremely',
];
const periodNightsOptions = [
  'No nights',
  '1-2 nights',
  '3-4 nights',
  '5-6 nights',
  'Every night',
];
const affectOptionsVariation = [
  'Not at all',
  'A little',
  'Moderately',
  'A lot',
  'Extremely',
];

const AtopicDermatitisControlTracker = props => { 

  const history = useHistory();

  const [createADCTInfo] = useMutation(atopicDermatitisControlTracker);

  const { onClose, date, userId } = props;

  const [saving, setSaving] = useState(false);
  const [symptoms, setSymptoms] = useState([]);
  const [itching, setItching] = useState([]);
  const [bothered, setBothered] = useState([]);
  const [troubleAsleep, setTroubleAsleep] = useState([]);
  const [affectActivities, setAffectActivities] = useState([]);
  const [affectEmotions, setAffectEmotions] = useState([]);

  const handleADCTTracker = async () => {
    setSaving(true); // Set saving state to true

    const input = {
      userid: userId,
      client_date: date,
      rate_symptoms: severityOptions.findIndex(option => option === symptoms[0]), 
      itching_episodes: periodDaysOptions.findIndex(option => option === itching[0]),
      rate_bothered: affectOptions.findIndex(option => option === bothered[0]), 
      affect_sleep: periodNightsOptions.findIndex(option => option === troubleAsleep[0]),
      affect_activities: affectOptionsVariation.findIndex(option => option === affectActivities[0]),
      affect_emotions: affectOptionsVariation.findIndex(option => option === affectEmotions[0]),
    };

    try {
      await createADCTInfo({
        variables: {
          input, 
        },
      });

      const currentTime = moment(date).utc().format(MIXPANEL_TIME_FORMAT);
      const selectedDayMoment = moment(date).utc().format(MIXPANEL_DAY_FORMAT);
      const trackerDate = `${selectedDayMoment}T${currentTime}`;

      MixpanelService.track(MixpanelEvents.ADCTTrackerUpdated, {
        ADCTStatus: input.rate_symptoms +  input.itching_episodes + input.rate_bothered + input.affect_sleep + input.affect_activities + input.affect_emotions,
        ADCTTrackerDate: trackerDate
      });

      history.push({
        pathname: '/trends',
        state: { scrollToADCT: true },
      });
    } catch (error) {
      console.error('Error creating AtopicDermatitisControlData:', error);
    } finally {
      setSaving(false);
    }
  };

  const isFormValid = symptoms.length > 0 && itching.length > 0 && bothered.length > 0 &&
                       troubleAsleep.length > 0 && affectActivities.length > 0 && 
                       affectEmotions.length > 0;
  
  return (
    <div className="main-dashboard page-provider-prep">
        <div className="provider-prep-container">
          <header>
            <div className="date-bar">
              <div className="main-dashboard__sidebar">
                <div className="sidebar-title">
                  <Unicons.UilTimes size="24" color="#000" onClick={onClose} />
                  <h1 className="headers headers__black100 headers__uppercase headers__mediumbold">
                    ADCT Quiz
                  </h1>
                </div>
              </div>
              <div className="header-buttons">
                <button
                  className="button button__secondary100-inverted button__btn-14-600 button-rectangle-medium buttonCancel"
                  onClick={onClose} >
                  Cancel
                </button>
              </div>
            </div>
          </header>
          <div className="manage-container">
                <div className="manage-content">
                    <div className="appointmentPrepStep">
                        <div className="card">
                          <div className="card-header">
                            Is your eczema under control? Complete all six questions to get your score.
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-header">
                            <h3>1. Over the last week, how would you rate your eczema-related symptoms (for example, itching, dry skin, skin rash)?</h3>
                          </div>
                          <Multiselect
                            maxItems={1}
                            options={severityOptions}
                            selectedOptions={symptoms}
                            onChange={setSymptoms}
                          />
                        </div>
                        <div className="card">
                          <div className="card-header">
                            <h3>
                              2. Over the last week, how many days did you have intense episodes of itching because of your eczema?
                            </h3>
                          </div>
                          <Multiselect
                            maxItems={1}
                            options={periodDaysOptions}
                            selectedOptions={itching}
                            onChange={setItching}
                          />
                        </div>

                        <div className="card">
                          <div className="card-header">
                            <h3>
                              3. Over the last week, how bothered have you been by your eczema?
                            </h3>
                          </div>
                          <Multiselect
                            maxItems={1}
                            options={affectOptions}
                            selectedOptions={bothered}
                            onChange={setBothered}
                          />
                        </div>

                        <div className="card">
                          <div className="card-header">
                            <h3>
                              4. Over the last week, how many nights did you have trouble falling or staying asleep because of your eczema?
                            </h3>
                          </div>
                          <Multiselect
                            maxItems={1}
                            options={periodNightsOptions}
                            selectedOptions={troubleAsleep}
                            onChange={setTroubleAsleep}
                          />
                        </div>

                        <div className="card">
                          <div className="card-header">
                            <h3>
                              5. Over the last week, how much did your eczema affect your daily activities?
                            </h3>
                          </div>
                          <Multiselect
                            maxItems={1}
                            options={affectOptionsVariation}
                            selectedOptions={affectActivities}
                            onChange={setAffectActivities}
                          />
                        </div>

                        <div className="card">
                          <div className="card-header">
                            <h3>
                              6. Over the last week, how much did your eczema affect your mood or emotions?
                            </h3>
                          </div>
                          <Multiselect
                            maxItems={1}
                            options={affectOptionsVariation}
                            selectedOptions={affectEmotions}
                            onChange={setAffectEmotions}
                          />
                        </div>

                        <div className="card">
                          <div className="card-header__center">
                                  © Atopic Dermatitis Control Tool Version 1 <br/>
                                  Sanofi Group and Regeneron Pharmaceuticals Inc. <br/>
                                  All Rights Reserved
                          </div>
                        </div>
                    </div>
                </div>
          </div>
          <div className="footer-buttons-bar">
            <div className="buttons-container">
              <Button
                onClick={onClose}
                className="button button__secondary100-inverted button__btn-14-600 button-rectangle-medium"
                text={'Cancel'}
              />
              <Button
                onClick={handleADCTTracker}
                className="button button__secondary100 button__btn-14-600 button-rectangle-medium"
                text={saving ? 'Saving...' : 'See your score'}
                disabled={saving || !isFormValid}
              />
            </div>
          </div>
        </div>
    </div>
  );
};

export default memo(AtopicDermatitisControlTracker);
