import moment from 'moment-timezone';

// SVGs
import {
  PoscoradIndicator,
} from '../../assets/icons';

const SEVERITIES = {
  Mild: 'Mild',
  Moderate: 'Moderate',
  Severe: 'Severe',
};

const PoscoradHistory = props => {
  const { history, averageLevel } = props;
  const lastPoscorad = history?.[0]?.level || null;
  let lastPoscoradDate = history?.[0]?.tracker_date || null;
  const averagePoscorad = averageLevel || null;

  const minLevel = history?.reduce((min, item) => item.level < min ? item.level : min, history[0]?.level) || null;
  const maxLevel = history?.reduce((max, item) => item.level > max ? item.level : max, history[0]?.level) || null;


  const todayLabel = moment(lastPoscoradDate).isSameOrAfter(moment(), 'day')
    ? 'Today'
    : moment(lastPoscoradDate).utc().format('MM/DD/YYYY');

  const getSeverityLabel = level => {
    if (!level) {
      return '';
    }
    if (level <= 25) {
      return SEVERITIES.Mild;
    }
    if (level <= 50) {
      return SEVERITIES.Moderate;
    }
    return SEVERITIES.Severe;
  };

  return (<>
    <div className="poscorad-history">
      <div className="poscorad-item poscorad-item-scale">
        <div className='center'>
            <h4 className="poscorad-item-subtitle">Last recorded: ({todayLabel}): </h4>
            <span className={`poscorad-item-severity ${getSeverityLabel(lastPoscorad)}`}>
              {lastPoscorad} {getSeverityLabel(lastPoscorad)}
            </span>
        </div>
        <div className="poscorad-scales">
          <div
            className="poscorad-scales-indicator"
            style={{ left: (lastPoscorad * 100 / 24) - 1 + '%', bottom: '3px' }}>
            <PoscoradIndicator percentage={(lastPoscorad * 100 / 24)} />
          </div>
          <div className="poscorad-scales-item"></div>
          <div className="poscorad-scales-item Moderate"></div>
          <div className="poscorad-scales-item Severe"></div>
          <div className="poscorad-scales-item Severe"></div>
        </div>
        <div className="poscorad-labels">
          <span>0</span>
          <span className={`severity ${SEVERITIES.Mild}`}>
            {SEVERITIES.Mild}
          </span>
          <span className="number-center">25</span>
          <span className={`severity ${SEVERITIES.Moderate}`}>
            {SEVERITIES.Moderate}
          </span>
          <span className="number-center">50</span>
          <span className={`severity ${SEVERITIES.Severe}`}>
            {SEVERITIES.Severe}
          </span>
          <span className="number-right">103</span>
        </div>
      </div>
    </div>
    <div className="poscorad-history">  
      <div className="poscorad-item center">
        <span className="poscorad-item-title">Past 90 days average</span>
        <span
          className={`poscorad-item-severity ${getSeverityLabel(
            averagePoscorad,
          )}`}>
          {averagePoscorad || '-'} {getSeverityLabel(averagePoscorad) || 'Not enough data'}
        </span>
      </div>
      <div className="poscorad-item center">
        <span className="poscorad-item-title">Past 90 days range</span>
        <span
          className="poscorad-item-severity NA">
          Lowest {minLevel|| 'Not enough data'} - Highest {maxLevel|| 'Not enough data'}
        </span>
      </div>
    </div>
  </>);
};

export default PoscoradHistory;
