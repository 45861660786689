import React, { useState, useRef, useEffect } from 'react';
import { CloseIcon } from '../../assets/icons';

function Tutorial({
  children,
  titles,
  subtitles,
  texts,
  urlsGif,
  onClickNext,
}) {
  const [showTooltip, setShowTooltip] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const tooltipContentRef = useRef(null);
  const tooltipIndicatorRef = useRef(null);
  const tooltipContainerRef = useRef(null);

  const toggleTooltip = e => {
    e.stopPropagation();
    setShowTooltip(!showTooltip);
    setCurrentIndex(0);
    onClickNext(titles[0]);
  };

  const nextItem = () => {
    if (currentIndex < titles.length - 1) {
      setCurrentIndex(currentIndex + 1);
      onClickNext(titles[currentIndex + 1]);
      positionTooltip();
    }
  };

  const previousItem = () => {
    if (currentIndex < titles.length + 1) {
      setCurrentIndex(currentIndex - 1);
      onClickNext(titles[currentIndex - 1]);
      positionTooltip();
    }
  };

  useEffect(() => {
    positionTooltip();
  }, [showTooltip, currentIndex]);

  useEffect(() => {
    const container = document.querySelector('.wrapper');
  
    if (container) {
      container.addEventListener('scroll', positionTooltip);
    }
  
    return () => {
      if (container) {
        container.removeEventListener('scroll', positionTooltip);
      }
    };
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (tooltipContentRef.current && !tooltipContentRef.current.contains(event.target)) {
        setShowTooltip(!showTooltip);
        setCurrentIndex(0);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [tooltipContentRef,showTooltip,currentIndex]);

  const positionTooltip = () => {
    const exceptionElement = document.querySelectorAll('.exception-element');
    const container = document.querySelector('.wrapper');
    
    const exceptionElementRect = exceptionElement[0] 
      ? exceptionElement[0].getBoundingClientRect() 
      : tooltipContainerRef.current?.getBoundingClientRect();
  
    if (exceptionElementRect && tooltipContentRef.current && container) {
      const tooltipWidth = tooltipContentRef.current.offsetWidth;
      const tooltipHeight = tooltipContentRef.current.offsetHeight;
      const tooltipIndicatorPosition = { top: '10px', left: '-5px' };
  
      let left = exceptionElementRect.left + exceptionElementRect.width + 9;
      let top = exceptionElementRect.top - 10;
      let bottom = null;
  
      if (left + tooltipWidth > window.innerWidth) {
        left = exceptionElementRect.left - tooltipWidth - 9;
        tooltipIndicatorPosition.top = '10px';
        tooltipIndicatorPosition.left = 'calc(100% - 10px)';
      }
    
      if (left < 0) {
        left = exceptionElementRect.left + (exceptionElementRect.width / 2) - (tooltipWidth / 2) - 9;
        tooltipIndicatorPosition.top = '10px';
        tooltipIndicatorPosition.left = '-5px';
      }
  
      if (top + tooltipHeight > window.innerHeight) {
        left = exceptionElementRect.left + (exceptionElementRect.width / 2) - (tooltipWidth / 2) - 5;
        top = false;
        bottom = window.innerHeight - exceptionElementRect.top + 10;
        tooltipIndicatorPosition.top = 'calc(100% - 10px)';
        tooltipIndicatorPosition.left = '50%';
      }
  
      tooltipContentRef.current.style.left = `${left}px`;
      if (top !== false) {
        tooltipContentRef.current.style.bottom = null;
        tooltipContentRef.current.style.top = `${top}px`;
      } else {
        tooltipContentRef.current.style.bottom = `${bottom}px`;
        tooltipContentRef.current.style.top = null;
      }
  
      tooltipIndicatorRef.current.style.left = tooltipIndicatorPosition.left;
      tooltipIndicatorRef.current.style.top = tooltipIndicatorPosition.top;
    }
  };

  return (
    <>
      <div className={`overlay ${showTooltip ? 'visible' : ''}`} ></div>
      <div
        onClick={toggleTooltip}
        ref={tooltipContainerRef}
        className={`tooltip-container ${
          (showTooltip && currentIndex === 0)
            ? 'exception-element' 
            : ''
        }  exception-element-icon`}>
        {children}
      </div>
      {showTooltip &&
        (currentIndex !== 0 ? (
          <>
            <div
              className={'tooltip-white-content'}
              ref={tooltipContentRef}
              onClick={e => e.stopPropagation()}>
              <div
                className={'tooltip-content-indicator-white'}
                ref={tooltipIndicatorRef}></div>
              <div className="title-close-container">
                <h1 className={'title-white-card'}>{titles[currentIndex]}</h1>
                <button
                  className="close-button-tutorial"
                  onClick={toggleTooltip}>
                  <CloseIcon />
                </button>
              </div>
              {subtitles[currentIndex].length > 0 && (
                <>
                  <hr />
                  <img src={urlsGif[currentIndex]} alt="NEA gif" />
                  <br />
                  <p className="subtitles">{subtitles[currentIndex]}</p>
                </>
              )}
              <p className={'text-white-card'}>{texts[currentIndex]}</p>
              {subtitles[currentIndex].length > 0 && (
                <>
                  <br />
                  <br />
                  <div className="stepper-points">
                    {currentIndex === titles.length - 1 ? (
                      <div className="container-dots">
                        <div className="circle2"></div>
                        <div className="circle1"></div>
                      </div>
                    ) : (
                      <div className="container-dots">
                        <div className="circle1"></div>
                        <div className="circle2"></div>
                      </div>
                    )}
                  </div>
                </>
              )}
              <hr />
              <div>
                {currentIndex === titles.length - 1 ? (
                  <div className="actions2">
                    <span
                      className="action-buttons margin-buttons"
                      onClick={previousItem}>
                      Previous
                    </span>
                    <span className="action-buttons" onClick={toggleTooltip}>
                      Finish
                    </span>
                  </div>
                ) : (
                  <div className="actions2">
                    <span
                      className="action-buttons margin-buttons"
                      onClick={previousItem}>
                      Previous
                    </span>
                    <span className="action-buttons" onClick={nextItem}>
                      Next step
                    </span>
                  </div>
                )}
              </div>
            </div>
          </>
        ) : (
          <>
            <div
              className="tooltip-white-content"
              ref={tooltipContentRef}
              onClick={e => e.stopPropagation()}>
              <div
                className="tooltip-content-indicator-white"
                ref={tooltipIndicatorRef}></div>
              <div className="title-close-container">
                <h1 className={'title-white-card'}>{titles[currentIndex]}</h1>
                <button
                  className="close-button-tutorial"
                  onClick={toggleTooltip}>
                  <CloseIcon />
                </button>
              </div>
              <p className="text-white-card">{texts[currentIndex]}</p>
              <hr />
              <div className="actions">
                {currentIndex < titles.length - 1 && (
                  <span className="action-buttons" onClick={nextItem}>
                    {currentIndex === 0 ? 'Find out how' : 'Next'}
                  </span>
                )}
              </div>
            </div>
          </>
        ))}
    </>
  );
}

export default Tutorial;
