import React from 'react';

const FlareNotTracked = () => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="30" cy="30" r="30" fill="#8394B5" />
      <path
        d="M29.9995 12.3535C27.6821 12.3535 25.3873 12.81 23.2463 13.6968C21.1053 14.5837 19.1599 15.8835 17.5212 17.5222C14.2117 20.8317 12.3525 25.3203 12.3525 30.0006C12.337 34.0755 13.748 38.0274 16.3407 41.1712L12.8113 44.7006C12.5664 44.9487 12.4006 45.2639 12.3346 45.6062C12.2686 45.9485 12.3056 46.3027 12.4407 46.6241C12.5873 46.9416 12.8249 47.2084 13.1234 47.3907C13.4219 47.5729 13.7678 47.6623 14.1172 47.6476H29.9995C34.6798 47.6476 39.1684 45.7884 42.4779 42.4789C45.7874 39.1695 47.6466 34.6809 47.6466 30.0006C47.6466 25.3203 45.7874 20.8317 42.4779 17.5222C39.1684 14.2128 34.6798 12.3535 29.9995 12.3535ZM29.9995 44.1182H18.3701L20.0113 42.477C20.34 42.1464 20.5245 41.6991 20.5245 41.2329C20.5245 40.7667 20.34 40.3195 20.0113 39.9888C17.7006 37.6806 16.2616 34.6427 15.9396 31.3926C15.6175 28.1424 16.4324 24.8812 18.2452 22.1644C20.0581 19.4477 22.7568 17.4436 25.8816 16.4935C29.0064 15.5434 32.364 15.7061 35.3823 16.954C38.4005 18.2018 40.8928 20.4575 42.4344 23.3369C43.976 26.2162 44.4717 29.5409 43.8368 32.7447C43.202 35.9485 41.476 38.833 38.9528 40.9069C36.4297 42.9808 33.2656 44.1157 29.9995 44.1182Z"
        fill="white"
      />
    </svg>
  );
};

export default FlareNotTracked;
