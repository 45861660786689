import abbvie from './abbvie/abbvie-transparent.png';
import lilly from './LillyMaster 2/Red/LillyLogo_RGB_Red.png';
import pfizer from './Pfizer/pfizer_rgb_pos.png';
import sanofi from './Sanofi-RGN/Sanofi-Regeneron-Logo_vertical.png';
import leo from './LEOpharma/LEO_Logo_Black_RGB.jpg';

const sponsors = [
  {
    name: 'Lilly',
    img: lilly,
  },
  {
    name: 'Sanofi Genzyme Regeneron',
    img: sanofi,
  },
  {
    name: 'Pfizer',
    img: pfizer,
  },
  {
    name: 'abbvie',
    img: abbvie,
  },
  {
    name: 'Leo pharma',
    img: leo,
  },
];

export default sponsors;
