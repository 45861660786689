import React, { useEffect,useState } from 'react';
import moment from 'moment-timezone';

// SVGs
import {
  PoscoradIndicator,
} from '../../assets/icons';

// COMPONENTS
import { Link } from 'react-router-dom';

// APOLLO
import { useQuery } from 'react-apollo';
import { LatestsAtopicDermatitisControlData } from '../../libs/graphql/queries/data';

// Helper function to determine control status based on score
const getADCTStatus = (score) => {
  if (score === null) {
    return 'Not enough data';
  }
  if (score <= 7) {
    return 'Controlled';
  }
  return 'Not controlled';
};

// Helper function to determine control status based on score
const getADCTClass = (score) => {
  if (score <= 7) {
    return 'Clear';
  }
  return 'Severe';
};


const AtopicDermatitisControlScale = ({ hideADCTResume, hideADCTResults, userId, onADCTData }) => {
  const [results, setResults] = useState({
    latestDate: 0,
    latestResult: 0,
    minResult: 0,
    maxResult: 0,
    averageResult: 0,
    todayLabel: null
  });

  const { data } = useQuery(LatestsAtopicDermatitisControlData, {
    variables: { userid: userId },
  });

  useEffect(() => {
    if (data) {
      const ADCTData = data.latestAtopicDermatitisControlData;

      const latestDate = ADCTData[0]?.client_date || 0;

      const resultValues = ADCTData.map(
        item =>
          (item.rate_symptoms || 0) +
          (item.itching_episodes || 0) +
          (item.rate_bothered || 0) +
          (item.affect_sleep || 0) +
          (item.affect_activities || 0) +
          (item.affect_emotions || 0)
      );


      const minResult = resultValues.length === 0 ? null : Math.min(...resultValues);
      const maxResult = resultValues.length === 0 ? null : Math.max(...resultValues);
      const averageResult =
        resultValues.length > 0
          ? parseFloat(
              (resultValues.reduce((sum, value) => sum + value, 0) / resultValues.length).toFixed(2),
            )
          : null;
      const latestResult = resultValues.length > 0 && !isNaN(resultValues[0]) ? resultValues[0] : null;

      const todayLabel = !latestDate
        ? '-'
        : moment(+latestDate).utc().isSameOrAfter(moment(), 'day')
        ? 'Today'
        : moment(+latestDate).utc().format('MM/DD/YYYY');

      setResults({
        latestDate,
        latestResult,
        minResult,
        maxResult,
        averageResult,
        todayLabel,
      });

      if (onADCTData) {
        onADCTData(resultValues[0] !== undefined);
      }
    }
  }, [data, onADCTData]);

  const { todayLabel, latestResult, minResult, maxResult, averageResult } = results;

  return (
    <>
      <div className="adct-summary-history">
        <div className="summary-item summary-item-scale">
          <div className="center">
            <h4 className="summary-item-subtitle">
              Last recorded: ({todayLabel}): &nbsp;
              {!hideADCTResults && latestResult !== null && (
                <Link to={`/trends/AtopicDermatitisControlResults`} onClick={() => {}}>
                  <span className="text text__14p600 text__secondary100 text__capitalized text__mt10 pointer-flare">
                    View Results
                  </span>
                </Link>
              )}
            </h4>
            <span className={`summary-item-severity ${getADCTClass(latestResult)}`}>
              {latestResult === null ? '-' : latestResult.toString()} {getADCTStatus(latestResult)}
            </span>
          </div>
          <div className="summary-scales">
            <div
              className="summary-scales-indicator"
              style={{ left: `${(latestResult * 100) / 24 - 1}%`, bottom: '3px' }}
            >
              <PoscoradIndicator percentage={(latestResult * 100) / 24} />
            </div>
            <div className="summary-scales-item"></div>
            <div className="summary-scales-item-lg-Severe"></div>
          </div>
          <div className="summary-labels">
            <span>0</span>
            <span className={`severity Mild`}>Controlled</span>
            <span className="number-center">7</span>
            <span className={`severity Severe-lg`}>Not controlled</span>
            <span className="number-right">24</span>
          </div>
        </div>
      </div>
      {!hideADCTResume && (
        <div className="adct-summary-history">
          <div className="summary-item center">
            <span className="summary-item-title">Past 90 days average</span>
            <span className={`summary-item-severity ${getADCTClass(averageResult)}`}>
            {averageResult === null ? '-' : averageResult.toString()} {getADCTStatus(averageResult)}
            </span>
          </div>
          <div className="summary-item center">
            <span className="summary-item-title">Past 90 days range</span>
            <span className="summary-item-severity NA">
              Lowest {minResult} – Highest {maxResult}
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default AtopicDermatitisControlScale;
