import React from 'react';

//COMPONENTS
import Modal from '../../shared/Modal';
import Button from '../../shared/button';

//ASSETS
import symptoms   from '../../../assets/images/atopic-dermatitis-control/symptoms.svg';
import activities from '../../../assets/images/atopic-dermatitis-control/activities.svg';
import itching    from '../../../assets/images/atopic-dermatitis-control/itching.svg';
import bother     from '../../../assets/images/atopic-dermatitis-control/bother.svg';
import sleep      from '../../../assets/images/atopic-dermatitis-control/sleep.svg';
import emotions   from '../../../assets/images/atopic-dermatitis-control/emotions.svg';

const AtopicDermatitisControlTutorial = ({isModalOpen, onClose}) => {
     
  return (
    <Modal 
      size='ml'
      isOpen={isModalOpen}
      header={'LEARN ABOUT ADCT'}
      footer={
        <div className="button-container">
          <Button
            onClick={onClose}
            className="button button__secondary100 button__btn-14-600 button-rectangle-small"
            text={'Close'}
          />
        </div>}
      onClose={onClose}
    >
      <div className='adct-tutorial'>
        <ul>
            <li>The Atopic Dermatitis Control Tool (ADCT) quiz is a validated, brief and easily scored patient self-assessment tool. </li>
            <li>Six concise questions help to evaluate different dimensions of eczema control.</li>
            <li>A higher score means lower control of your eczema.  
                Your eczema may not be well-controlled if your score is 7 or greater, 
                or if your score increased by 5 or more points since you last used ADCT.</li>
            <li>You can use your ADCT quiz results to start a meaningful discussion with your doctor.</li>
        </ul>
        <span className='title'>The ADCT quiz measures eczema control across these six domains:</span>
        <hr/>
        <div className='adct-tutorial-img'>
        <img src={symptoms  } alt="Overall severity of AD symptoms" />
        <img src={activities} alt="Frequently of intense episodes of itching" />
        <img src={itching   } alt="Extent of AD-Related bother" />
        <img src={bother    } alt="Frequency of sleep impact" />
        <img src={sleep     } alt="Impact of AD on daily activities" />
        <img src={emotions  } alt="Impact of AD on mood or emotions" />
        </div>
        <br/>
        <span className='title'>ADCT Quiz Scale</span>
        <div className="adct-summary-history">
          <div className="summary-item summary-item-scale">
            <div className="summary-scales">
              <div className="summary-scales-item"></div>
              <div className="summary-scales-item-lg-Severe"></div>
            </div>
            <div className="summary-labels">
              <span>0</span>
              <span className={`severity Mild`}>
                Controlled
              </span>
              <span className="number-center">7</span>
              <span className={`severity Severe-lg`}>
                Not controlled
              </span>
              <span className="number-right">24</span>
            </div>
          </div>
        </div>
        <p className='description'>
            This about page is cited from Sanofi Group and Regeneron Pharmaceuticals Inc. <br/>
            "About Atopic Dermatitis Control Tool (ADCT)." AD Control Tool, January 2023, <br/>
            <a href={'https://eprovide.mapi-trust.org/'} target={'_blank'} rel="noreferrer">
                https://eprovide.mapi-trust.org/
            </a>
            <br/>
            Accessed 2 July 2024.</p>
      </div>
    </Modal>
  );
}

export default AtopicDermatitisControlTutorial;
