import gql from 'graphql-tag';

export const FlareTrackerData = gql`
  query dailyUserData($id: ID!, $today: String!) {
    dailyUserData(id: $id, today: $today) {
      tracker_date
      flareData {
        id
        flare_day
        days_streak
      }
    }
  }
`;
