import React, { useState } from 'react';

//SVGs
import {
  HelperIcon,
  PoscoradIndicator,
  ProgressCircle,
} from '../../assets/icons';
import TrackPoscoradOne from '../../assets/GIFs/Track_poscorad_1.gif';
import TrackPoscoradTwo from '../../assets/GIFs/Track_poscorad_2.gif';

// COMPONENTS
import Tutorial from './Tutorial';

const SEVERITIES = {
  Mild: 'Mild',
  Moderate: 'Moderate',
  Severe: 'Severe',
};

const MAX_POSCORAD_VALUE = 103;

const getSeverityLabel = level => {
  if (level === null) {
    return 'Not tracked';
  }
  if (!level) {
    return '';
  }
  if (level <= 25) {
    return SEVERITIES.Mild;
  }
  if (level <= 50) {
    return SEVERITIES.Moderate;
  }
  return SEVERITIES.Severe;
};

const getSeverityColor = level => {
  if (!level) {
    return '#8394B5';
  }
  if (level < 33) {
    return '#FF9672';
  } else if (level < 66) {
    return '#E95D42';
  } else {
    return '#B33D26';
  }
};

const PoScoradTutorial = {
  titles: [
    'What is the PO-SCORAD?',
    'Track your symptoms',
    'View Your PO-SCORAD Score',
  ],
  texts: [
    `The PO-SCORAD is a score based on how intense your eczema symptoms are and how much of your body’s skin is affected by them.`,
    `Your PO-SCORAD score is calculated by updating your skin, itch and sleep trackers.`,
    `Want to see how you’re doing? Complete all 3 trackers (skin, itch and sleep) and check back here for your most recent score.
    Use your score at your next appointment to help you and your doctor discuss your symptoms and evaluate if your treatment plan is working.`,
  ],
  subtitles: ['', `Track Your Symptoms`, `View Your PO-SCORAD Score`],
  urlsGif: ['', TrackPoscoradOne, TrackPoscoradTwo],
};

const PoScoradSeverity = props => {
  const {
    dateText,
    skinData,
    itchData,
    sleepData,
    poscoradData,
    newClassName,
  } = props;

  const [activeTutorialId, setActiveTutorialId] = useState(null);

  const activeTutorialUpdate = id => {
    setActiveTutorialId(id);
  };

  const completedTrackers =
    (skinData ? 1 : 0) + (itchData ? 1 : 0) + (sleepData ? 1 : 0);

  const poscoradPercentage =
    poscoradData != null ? (poscoradData.level * 103) / MAX_POSCORAD_VALUE : 0;

  return (
    <>
      {poscoradData ? (
        <div
          className={'tracker-button tracker-button-poscorad ' + newClassName}>
          <div className="tracker-button-content tracker-button-content-results">
            <div className="tracker-button-title">
              <label className="tracker-button-label">Your PO-SCORAD</label>
              <Tutorial
                titles={PoScoradTutorial.titles}
                texts={PoScoradTutorial.texts}
                subtitles={PoScoradTutorial.subtitles}
                urlsGif={PoScoradTutorial.urlsGif}
                onClickNext={activeTutorialUpdate}>
                <div className="tooltip-trigger">
                  <span className="icon-instructions">
                    <HelperIcon />
                  </span>
                </div>
              </Tutorial>
            </div>
            <div className="tracker-button-title">
              <div
                className="poscorad-dot"
                style={{
                  background: getSeverityColor(poscoradData.level),
                }}></div>
              <h3 className="result">{poscoradData.level}</h3>
              <h3 className="result">{getSeverityLabel(poscoradData.level)}</h3>
            </div>
          </div>
          <div className="poscorad-tracker-text poscorad-history">
            <div className="poscorad-item poscorad-item-scale">
              <div className="poscorad-labels">
                <span>0</span>
                <span className="severity Mild"></span>
                <span className="number-center">25</span>
                <span className="severity Moderate"></span>
                <span className="number-center">50</span>
                <span className="severity Severe"></span>
                <span className="number-right">103</span>
              </div>
              <div className="poscorad-scales">
                <div
                  className="poscorad-scales-indicator"
                  style={{ left: poscoradPercentage + '%', bottom: '2px' }}>
                  <PoscoradIndicator percentage={poscoradPercentage} />
                </div>
                <div className="poscorad-scales-item-separator"></div>
                <div className="poscorad-scales-item"></div>
                <div className="poscorad-scales-item-separator"></div>
                <div className="poscorad-scales-item Moderate"></div>
                <div className="poscorad-scales-item-separator"></div>
                <div className="poscorad-scales-item Severe"></div>
                <div className="poscorad-scales-item-separator"></div>
                <div className="poscorad-scales-item Severe"></div>
                <div className="poscorad-scales-item-separator"></div>
              </div>
              <div className="poscorad-labels">
                <span></span>
                <span className="severity Mild">{SEVERITIES.Mild}</span>
                <span className="number-center"></span>
                <span className="severity Moderate">{SEVERITIES.Moderate}</span>
                <span className="number-center"></span>
                <span className="severity Severe">{SEVERITIES.Severe}</span>
                <span className="number-right"></span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={'tracker-button ' + newClassName}>
          <div className="tracker-button-icon">
            <ProgressCircle progress={completedTrackers} />
          </div>
          <div className="tracker-button-content">
            <label className="tracker-button-label">Complete your</label>
            <div className="tracker-button-title">
              <h3>PO-SCORAD</h3>
              <Tutorial
                titles={PoScoradTutorial.titles}
                texts={PoScoradTutorial.texts}
                subtitles={PoScoradTutorial.subtitles}
                urlsGif={PoScoradTutorial.urlsGif}
                onClickNext={activeTutorialUpdate}>
                <div className="tooltip-trigger">
                  <span className="icon-instructions">
                    <HelperIcon />
                  </span>
                </div>
              </Tutorial>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PoScoradSeverity;
