import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';

// COMPONENTS
import FlareTracker from '../trackers/FlareTracker';
import Tutorial from './Tutorial';

// UTILS
import MixpanelService, { MixpanelEvents } from '../../libs/mixpanel';

//SVGs
import {
  FlareFullDay,
  SkinToday,
  GodFullDay,
  FlareNotTracked,
  HelperIcon,
} from '../../assets/icons';

// APOLLO
import { useQuery } from 'react-apollo';
import { FlareTrackerData } from '../../libs/graphql/queries/flare-tracker';

const FlareTutorial = {
  titles: ['Flare day or good day?'],
  subtitles: [``],
  texts: [
    'Tracking when you’re flaring and not flaring helps you quickly and easily monitor your eczema.',
  ],
  urlsGif: [''],
};

const FLARE_TRACKER_SHOWN = 'FLARE_TRACKER_SHOWN';

const FlareTrackerInfo = props => {
  const { date, userId, flareDay, onChange, source } = props;
  const trackerDate = moment(date);
  const today = moment().format('YYYY-MM-DD');
  const daysDiff = moment(today).diff(trackerDate, 'days');
  const [showFlareTracker, setShowFlareTracker] = useState(false);
  const [showFlareTrackerNews, setShowFlareTrackerNews] = useState(false);
  const [flareData, setFlareData] = useState(null);

  // Queries for daily diet data
  const { data, refetch, error } = useQuery(FlareTrackerData, {
    variables: {
      id: userId,
      today: date,
    },
  });

  const checkFirstLogin = () => {
    const firstLoginValue = localStorage.getItem(FLARE_TRACKER_SHOWN);
    const today = moment().format('YYYY-MM-DD');

    if (!firstLoginValue) {
      setShowFlareTrackerNews(true);
      setShowFlareTracker(true);
    } else if (firstLoginValue !== today) {
      setShowFlareTracker(true);
    }

    localStorage.setItem(FLARE_TRACKER_SHOWN, today);
  };

  useEffect(() => {
    checkFirstLogin();
  }, []);

  useEffect(() => {
    setFlareData(data?.dailyUserData?.flareData ?? null);
  }, [data]);

  useEffect(() => {
    refetch();
  }, [date, refetch]);

  useEffect(() => {
    if (error) {
      setFlareData(null);
    }
  }, [error]);

  const onCloseFlareTracker = () => {
    setShowFlareTracker(false);
  };

  const onFlareTrackerPress = () => {
    if (!editable) {
      return;
    }
    setShowFlareTracker(!showFlareTracker);
    MixpanelService.track(MixpanelEvents.FlareTrackerTapped);
  };

  const onTutorialClicked = () => {
    MixpanelService.track(MixpanelEvents.FlareTrackerTUT, {
      Progress: 'Completed',
    });
  };

  const onChangeFlare = flaring => {
    refetch();
    if (onChange) {
      onChange(flaring);
    }
  };

  const flare_day = flareDay !== undefined ? flareDay : flareData?.flare_day;
  const flare_registered = flare_day === true || flare_day === false;
  const editable = daysDiff <= 7;

  return (
    <>
      <div
        className={`tracker-button tracker-button-flare ${
          !editable ? 'tracker-button-disabled' : ''
        }`}
        onClick={onFlareTrackerPress}>
        {flare_registered || !editable ? (
          <>
            <div className="tracker-button-icon">
              {flare_day === true ? (
                <FlareFullDay />
              ) : flare_day === false ? (
                <GodFullDay />
              ) : (
                <FlareNotTracked />
              )}
            </div>
            <div className="tracker-button-content">
              <label className="tracker-button-label">Your day:</label>
              <span className="tracker-button-title">
                <h3>
                  {flare_day === true
                    ? 'Flaring'
                    : flare_day === false
                    ? 'Flare free!'
                    : 'Not tracked'}
                </h3>
                <Tutorial
                  titles={FlareTutorial.titles}
                  texts={FlareTutorial.texts}
                  subtitles={FlareTutorial.subtitles}
                  urlsGif={FlareTutorial.urlsGif}
                  onClickNext={() => {}}>
                  <div className="tooltip-trigger" onClick={onTutorialClicked}>
                    <span className="icon-instructions">
                      <HelperIcon />
                    </span>
                  </div>
                </Tutorial>
              </span>
            </div>
          </>
        ) : (
          <>
            <div className="tracker-button-icon">
              <SkinToday />
            </div>
            <div className="tracker-button-content">
              <label className="tracker-button-label">Are you</label>
              <div className="tracker-button-title">
                <h3>flaring today?</h3>
                <Tutorial
                  titles={FlareTutorial.titles}
                  texts={FlareTutorial.texts}
                  subtitles={FlareTutorial.subtitles}
                  urlsGif={FlareTutorial.urlsGif}
                  onClickNext={() => {}}>
                  <div className="tooltip-trigger" onClick={onTutorialClicked}>
                    <span className="icon-instructions">
                      <HelperIcon />
                    </span>
                  </div>
                </Tutorial>
              </div>
            </div>
          </>
        )}
      </div>
      <FlareTracker
        showFlareTracker={showFlareTracker}
        showFlareTrackerNews={showFlareTrackerNews}
        userId={userId}
        onClose={onCloseFlareTracker}
        date={date}
        flareData={flareData}
        onChange={onChangeFlare}
        source={source}
      />
    </>
  );
};

export default FlareTrackerInfo;
