import React, { useState } from 'react';

// SVGs
import {
  HelperIcon,
} from '../../assets/icons';

// COMPONENTS
import AtopicDermatitisControlTutorial from '../trackers/ADCT/ADCTTutorial';

//Mixpanel
import MixpanelService, { MixpanelEvents } from '../../libs/mixpanel';
import AtopicDermatitisControlScale from '../shared/ADCTScale';

const AtopicDermatitisControlSummary = props => {
  const { userId, onADCTData } = props;    

  const [showModal, setShowModal] = useState(false);

  const handleADCTLoad = (hasData) => {
    onADCTData(hasData);
  };


  return (<>
      <div className="trends-separator" />
      <div className="trends-content">
        <aside className="trends-sidebar"></aside>
        <div className="trends-data">
          <div className="trends-data-card" >
            <div class="trends-summary">
                <header>
                    <h4>ADCT Quiz Score
                      <div
                        onClick={e => {
                          e.stopPropagation();
                          setShowModal(true)
                          MixpanelService.track(MixpanelEvents.ADCTTrackerToolTipTapped);
                        }}
                        className={`tooltip-container exception-element-icon`}
                      >
                        <div className="tooltip-trigger" >
                          <span className="icon-instructions">
                            <HelperIcon />
                          </span>
                        </div>
                      </div>
                    </h4>
                </header>
                <hr />
                <p className={'adct-text'}>
                  If your score is at least 7 points OR has increased by 5 points or more since your last result, you may  want to 
                  talk to your doctor.
                </p>
                <AtopicDermatitisControlScale 
                  userId={userId}
                  onADCTData={handleADCTLoad}
                />
                <AtopicDermatitisControlTutorial isModalOpen={showModal} onClose={() => {setShowModal(false)}}/>
            </div>
          </div>
        </div>
      </div>
    </>);
};


export default AtopicDermatitisControlSummary;
